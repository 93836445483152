/*
TitleCard:
Creates a title card for the sections.
Creates the shape based on the input provided.
Can pick between only 2 colors for circles and 1 for rotated squares.
*/

const TitleCard = ({ title = "", color = "", shape = "" }) => {
  return (
    <div className={`TitleCard ${title}`}>
      <div className={`${color} ${shape}`}></div>
      <p className="title-text">{title}</p>
      <div className="dividing-line"></div>
    </div>
  );
};

export default TitleCard;
