/*
Card:
Outer container for content.
Automatically generates <p> tags for the content passed in as a prop.
This is done with dangerouslySetInnerHTML, but only after sanitizing the input.
*/
import { sanitize } from "dompurify";
import PropTypes from "prop-types";

const renderContent = (list) => list.map((data, idx) => 
  <p key={idx} dangerouslySetInnerHTML={{ __html: sanitize(data) }}></p>);

const Card = ({ content }) => {
  return (
    <div className="Card text">
      {renderContent(content)}
    </div>
  )
};

Card.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired
};

Card.defaultProps = {
  content: [
    "Hey there, I'm Alex",
    "I'm a full-stack developer.",
    "And a graduate of UCSD and Rithm School,"
  ]
}

export default Card;