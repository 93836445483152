import Card from "./components/Card";
import Monogram from "./components/Monogram";
import UnderlinedParagraph from "./components/UnderlinedParagraph";
import TitleCard from "./components/TitleCard";
import "./App.scss";

/* Static Resources and Images */
import content from "./content/content.json";
import me from "./static/me.jpg";
import microblog from "./static/microblog.png";
import warbler from "./static/warbler.png";
import jobly from "./static/jobly.png";
import { ReactComponent as LILogo } from "./static/linkedin.svg";
import { ReactComponent as GHLogo } from "./static/github.svg";

const renderList = (list) => list.map((el, idx) => <li key={idx}>→ {el}</li>);

const App = () => {
  return (
    <div className="App">
      <div className="hero grid">
        <div className="main-width">
          <Monogram>ah</Monogram>
          <Card content={content.heroContent}></Card>
        </div>
        <img className="grid-img-sm" src={me} alt="me"></img>
      </div>

      <div className="about grid">
        <div className="main-width">
          <TitleCard title="about" color="blue" shape="circle" />
          <Card content={content.aboutContent}></Card>
          <ul className="text">{renderList(content.skillList)}</ul>
        </div>
      </div>

      <div className="projects grid">
        <div className="main-width">
          <TitleCard title="projects" shape="rotated-square" />
          <p className="text">{content.projects.title}</p>
          <img className="grid-img-md" src={microblog} alt="microblog"></img>
          <UnderlinedParagraph className="title-text project-title">
            <a href="https://microblog.hungale.com/">microblog</a>
          </UnderlinedParagraph>
          <Card content={content.projects.microblogContent}></Card>

          <img className="grid-img-md" src={warbler} alt="warbler"></img>
          <UnderlinedParagraph className="title-text project-title">
            Warbler
          </UnderlinedParagraph>
          <Card content={content.projects.warblerContent}></Card>

          <img className="grid-img-md" src={jobly} alt="jobly"></img>
          <UnderlinedParagraph className="title-text project-title">
            <a href="https://jobly.hungale.com/">Jobly</a>
          </UnderlinedParagraph>
          <Card content={content.projects.joblyContent}></Card>
        </div>
      </div>

      <div className="contact text">
        <TitleCard title="contact" color="teal" shape="circle" />
        <Card content={content.contactContent}></Card>
        <div className="footer">
          <a href="https://github.com/hungale">
            <GHLogo />
          </a>
          <a href="https://www.linkedin.com/in/hungale/">
            <LILogo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
